<template>
  <div class="building-selection-share-main">
    <div class="selection-title center">Building Selection {{ !pageErrorMessage && fullDetails ? `(${fullDetails.sqm_sqft})` : '' }}</div>
    <Loader v-if="loadingDetails" text="Loading Details" />
    <div v-else-if="pageErrorMessage" class="error-message error-text">{{ pageErrorMessage }}</div>
    <div v-else>
      <div class="center">Shared to {{ fullDetails?.main_page == 'BuildingSelectionDeveloper' ? 'developer' : 'client' }}: {{ fullDetails?.client_name }}</div>
      <div class="center">Expiring in {{ expiryTime?.fullText() }}</div>
      <div v-if="formSubmitted" class="center submitted-text">This form has been submitted. If you wish to edit, please request a new link.</div>
      <table>
        <tr>
          <th>Criteria</th>
          <th>Preference</th>
          <th>Weighting (1-10)</th>
        </tr>
        <tr v-for="(det,detInd) in shareDetails" :key="detInd">
          <td>
            <div>{{ det.description }}</div>
            <div class="criteria-remarks">{{ det.remarks }}</div>
          </td>
          <td><input class="preference-input" type="text" v-model="det.user_preference" :disabled="formSubmitted"></td>
          <td><input class="preference-input" type="text" v-model="det.weightage" :disabled="formSubmitted"></td>
        </tr>
      </table>
    </div>

    <Button v-if="!formSubmitted" class="save-button" @click="saveClicked" theme="submit">Save Details</Button>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { get, put } from '../../js/apiCall';
import { buildSignalR, compareData, dateDiff, hubDetails, question, showNoti } from '../../js/helper';

const route = useRoute();

//#region Data
const loadingDetails = ref(false); // When loading the page
const fullDetails = ref(null); // The full details of the share id including the client name and unit
const shareDetails = ref(null); // The details of the items that have been shared in the JSON file
const originalShareDetails = ref(null); // The original items that have been shared in the JSON file
const savingDetails = ref(false); // This is used when saving the details
const pageErrorMessage = ref(''); // The error message to show to the user if the share id has expired or invalid or any other messages
const currentExpiredTime = ref(null); // The current expired time
const expiryTime = ref(null); // The expiry time countdown
const countdownTimer = ref(null); // The timer for the countdown
const signalHub = ref(null); // The hub connection
const formSubmitted = ref(false); // To indicate the the form has been submitted
//#endregion Data

//#region Methods
const startCountdown = () => {
  countdownTimer.value = setInterval(() => {
    // Get the current difference
    expiryTime.value = (dateDiff(currentExpiredTime.value, new Date()));

    // Clear the interval once it is expired and show the expire message
    if (expiryTime.value.millis < 0) {
      clearInterval(countdownTimer.value);
      pageErrorMessage.value = 'This link has expired. Please get a new link.';
    }
  }, 1000);
}
const saveClicked = async () => {
  // Getting the difference
  let objToPost = compareData(originalShareDetails, shareDetails, 'benchmark_uid');
  
  // Continue to save only if there is changes
  if (objToPost.length == 0) {
    showNoti('There are no changes made to be saved.', 'error');
  } else {
    // Confirm if user wants to save the form
    let confirmSave = await question('Save Building Selection', 'Are you sure you want to save the details that you have entered?\nOnce saved, you are not able to edit again.\nA new link needs to be requested if you need to edit.', 'Save', 'Cancel');

    // Check if user confirms to save
    if (confirmSave == 'Save') {
      // Check the page name first and set the save url
      let saveURL = '';
      if (fullDetails.value.main_page == 'BuildingSelectionDeveloper') {
        // Set the URL for the developer
        saveURL = 'BuildingBenchmark/UpdateDeveloperBuildingBenchmark';
      } else {
        // Will update with client save url later
      }
      
      // Updating the developer
      savingDetails.value = true;
      let updateResult = await put(`${saveURL}?username=${fullDetails.value.main_page == 'BuildingSelectionDeveloper' ? 'developer' : 'client'}`, objToPost);

      // Checking for the result
      if (updateResult) {
        // Updating the submitted to N in the share form table to indicate that the form has been submitted
        let submitForm = await put(`FormShare/SubmitForm?shareId=${route.params.id}`);
        savingDetails.value = false;

        if (submitForm) {
          showNoti(`Successfully updated the ${fullDetails.value.main_page == 'BuildingSelectionDeveloper' ? 'developer' : 'client'}`, 'success');
          formSubmitted.value = true;
          await put(`FormShare/UpdateJson?id=${route.params.id}`, shareDetails.value);
        } else {
          showNoti(`There was an error occured while updating the ${fullDetails.value.main_page == 'BuildingSelectionDeveloper' ? 'developer' : 'client'}. Please try again.`, 'error')
        }
      } else {
        // Save failed
        showNoti(`There was an error occured while updating the ${fullDetails.value.main_page == 'BuildingSelectionDeveloper' ? 'developer' : 'client'}. Please try again.`, 'error');
        savingDetails.value = false;
      }
    }
  }
}
//#endregion Methods

//#region Lifecycle
onMounted(async () => {
  // Show the loading
  loadingDetails.value = true;

  // Connect to the signalr
  if (!signalHub.value) {
    signalHub.value = buildSignalR(hubDetails.BUILDINGHUBNAME);
    signalHub.value.start();
  }

  // Listening to the signal if there is an extend time
  signalHub.value.on(hubDetails.BUILDINGEXTENDTIME, async (id) => {
    // Checking if the id passed same as the current id
    if (id == route.params.id) {
      // Getting the full details again
      fullDetails.value = await get(`FormShare/GetShareFullDetails?id=${route.params.id}`);

      // Checking if the full details is not empty
      if (fullDetails.value) {
        // set the current expired time
        currentExpiredTime.value = new Date(fullDetails.value.expiry_datetime);
        // Show notification saying that the time has been extended
        showNoti('Expiry time extended', 'success');
      }
    }
  });

  // Get the full details for the share id
  fullDetails.value = await get(`FormShare/GetShareFullDetails?id=${route.params.id}`);

  // If the result from the DB is not empty
  if (fullDetails.value) {
    // Need to check here if the link has expired
    currentExpiredTime.value = new Date(fullDetails.value.expiry_datetime);
    expiryTime.value = (dateDiff(currentExpiredTime.value, new Date()));
    if (expiryTime.value.millis < 0) {
      pageErrorMessage.value = 'This link has expired. Please get a new link.';
    } else {
      // Set timeout to offset the millisecond difference
      setTimeout(() => {
        // Start the countdown
        startCountdown();
      }, expiryTime.value.millis);
      // Loading the details from the JSON saved
      shareDetails.value = await get(`FormShare/GetShareDetails?id=${route.params.id}`);
      // Setting the original share details
      originalShareDetails.value = JSON.parse(JSON.stringify(shareDetails.value));

      // Setting the form submitted value
      formSubmitted.value = fullDetails.value.submitted == 'Y';
    }
  } else {
    pageErrorMessage.value = 'This link is invalid.';
  }
  
  // Hide the loading
  loadingDetails.value = false;
});
onBeforeUnmount(() => {
  // Stop the connection to the hub
  if (signalHub.value) {
    signalHub.value.stop();
  }
});
//#endregion Lifecycle
</script>

<style scoped>
.building-selection-share-main {
  position: relative;
  padding: 10px;
  padding-bottom: 50px;
}
.selection-title {
  font-weight: bold;
  font-size: 1.3em;
}
.center {
  text-align: center;
}
.error-message {
  font-weight: bold;
  font-size: 1.1em;
  text-align: center;
  margin: 10px 0;
}
table {
  border-collapse: collapse;
  margin-top: 10px;
}
table tr th, table tr td {
  border: 1px solid gray;
  padding: 5px;
  white-space: nowrap;
}
td:first-child  {
  width: 100%;
}
td:not(:first-child) {
  text-align: center;
}
td > input {
  width: 100px;
}
.criteria-remarks {
  font-size: 0.8em;
  font-style: italic;
}
.preference-input {
  text-align: center;
}
.save-button {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: fit-content;
}
.submitted-text {
  font-weight: bold;
  font-style: italic;
}
</style>